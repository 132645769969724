<template>
  <b-modal
    size="sm"
    id="no-templates-modal"
    :hide-header="true"
    :hide-footer="true"
  >
    <template #default="{ close }">
      <div class="modal-status bg-danger"></div>

      <button type="button" class="btn-close" @click.prevent="close()"></button>

      <!-- Download SVG icon from http://tabler-icons.io/i/alert-triangle -->
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="icon mb-2 text-danger icon-lg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        stroke-width="2"
        stroke="currentColor"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
        <path d="M12 9v2m0 4v.01"></path>
        <path
          d="M5 19h14a2 2 0 0 0 1.84 -2.75l-7.1 -12.25a2 2 0 0 0 -3.5 0l-7.1 12.25a2 2 0 0 0 1.75 2.75"
        ></path>
      </svg>
      <h3>No templates yet!</h3>
      <div class="text-muted mb-3">
        You don't have any templates in your account yet. In order to make a
        workflow, you should add at least one HTML email template and one text
        email template.
      </div>

      <router-link
        :to="`/reputation/templates/`"
        :class="['btn', 'btn-outline']"
      >
        <span>Create a template</span>
      </router-link>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: "reputation.workflow.no-templates-modal",
  data: () => {
    return {};
  },
};
</script>
