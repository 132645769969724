<template>
  <div>
    <div class="row">
      <div class="col">
        <ol class="breadcrumb breadcrumb-arrows mb-3" aria-label="breadcrumbs">
          <li class="breadcrumb-item">
            <router-link :to="`/reputation/workflows/`">
              Workflows
            </router-link>
          </li>
          <li
            class="breadcrumb-item"
            aria-current="page"
            v-if="workflow.supersedes"
          >
            <router-link :to="`/reputation/workflow/${workflow.supersedes}/`">
              {{ workflow.name }}
            </router-link>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            <a href="#" v-if="workflow.supersedes"> Edit </a>
            <a href="#" v-else> Create Workflow </a>
          </li>
        </ol>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-8 mb-3">
        <div class="card">
          <div class="card-body">
            <h2 class="card-title" v-if="!workflow.supersedes">
              Create a new workflow
            </h2>
            <h2 class="card-title" v-else>Edit workflow</h2>
          </div>
          <div class="card-body">
            <div class="mb-3">
              <label for="name" class="form-label">Workflow Name</label>
              <input
                type="text"
                class="form-control"
                v-model="workflow.name"
                placeholder="New workflow"
              />
            </div>
            <label for="name" class="form-label">Build Workflow</label>
            <render-workflow
              :workflow="workflow"
              :editable="true"
              @new-action="addAction"
              @save-action="saveAction"
              @remove-action="removeAction"
            ></render-workflow>
          </div>

          <div class="card-footer">
            <button
              :class="['btn', 'btn-primary', valid ? '' : 'disabled']"
              @click="saveWorkflow"
            >
              Save Workflow
            </button>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card">
          <div class="card-header">
            <h3 class="card-title">What is a workflow?</h3>
          </div>

          <div class="card-body">
            <p>
              A <b>workflow</b> is a series of events that can help you request
              a review from your customer.
            </p>
            <p>
              Workflows are comprised of different actions. Actions can do tasks
              such as waiting a specific duration, waiting for a time window on
              a specific day, or sending an email.
            </p>
          </div>
        </div>
      </div>
    </div>

    <no-templates-modal></no-templates-modal>
  </div>
</template>

<script>
import ReputationManagementService from "../../services/ReputationManagementService";
import NoTemplatesModal from "./Workflow/NoTemplatesModal.vue";
import RenderWorkflow from "./Workflow/RenderWorkflow.vue";
export default {
  name: "reputation.edit-workflow",

  components: { RenderWorkflow, NoTemplatesModal },
  data: () => {
    return {
      workflow: {
        name: "",
        supersedes: null,
        raw_machine: {
          target_state: "complete",
          workflow: [],
        },
      },
    };
  },
  computed: {
    valid() {
      return (
        this.workflow.raw_machine.workflow.length > 0 &&
        this.workflow.name !== ""
      );
    },
  },
  methods: {
    async saveWorkflow() {
      await ReputationManagementService.saveWorkflow(
        this.$auth.activeCompany,
        this.workflow
      );
      this.$router.push({ path: "/reputation/workflows" });
    },
    addAction() {
      this.workflow.raw_machine.workflow.push({
        action_name: "",
        action_data: {},
      });
    },
    saveAction(id, action) {
      this.workflow.raw_machine.workflow.splice(id, 1, action);
    },
    removeAction(id) {
      console.log(id);
      this.workflow.raw_machine.workflow.splice(id, 1);
    },
  },
  async mounted() {
    if (this.$route.query.supersedes) {
      let supersedes = await ReputationManagementService.getWorkflow(
        this.$auth.activeCompany,
        this.$route.query.supersedes
      );
      this.workflow.name = supersedes.name;
      this.workflow.supersedes = supersedes.id;
      this.$set(this.workflow, "raw_machine", supersedes.raw_machine);
    }

    let templates = await ReputationManagementService.getTemplates(
      this.$auth.activeCompany
    );
    if (templates.length == 0) {
      this.$bvModal.show("no-templates-modal");
    }
  },
};
</script>
